import request from './request'

//企业序列
export const enterprise_list_request = () => {
    return request({
        method:'GET',
        url:'enterprise/list'
    })
}

//部门序列
export const department_list_request = p => {
    return request({
        method:'GET',
        url:'department/list',
        params: p
    })
}

//用户序列
export const staff_list_request = p => {
    return request({
        method:'GET',
        url:'staff/list',
        params: p
    })
}

//仓库序列
export const storeroom_list_request = p => {
    return request({
        method:'GET',
        url:'storeroom/list',
        params: p
    })
}

//客户序列
export const client_list_request = () => {
    return request({
        method:'GET',
        url:'client/list'
    })
}

//库位序列
export const location_list_request = p => {
    return request({
        method:'GET',
        url:'location/list',
        params: p
    })
}

//用户头像序列
export const avatar_list_request = () => {
    return request({
        method:'GET',
        url:'avatar/list'
    })
}

//银行卡序列
export const bank_card_list_request = () => {
    return request({
        method:'GET',
        url:'bank_card/list'
    })
}

//采购管理序列
export const purchase_list_request = () => {
    return request({
        method:'GET',
        url:'purchase/list'
    })
}

//招待管理序列
export const entertain_list_request = () => {
    return request({
        method:'GET',
        url:'entertain/list'
    })
}

//差旅管理序列
export const travel_list_request = () => {
    return request({
        method:'GET',
        url:'travel/list'
    })
}

//备用金序列
export const petty_list_request = () => {
    return request({
        method:'GET',
        url:'petty/list'
    })
}

//招待客户序列
export const entertain_client_list_request = () => {
    return request({
        method:'GET',
        url:'entertain_client/list'
    })
}

//报销类目序列
export const reimbursement_category_list_request = () => {
    return request({
        method:'GET',
        url:'reimbursement_category/list'
    })
}

//差旅目的地序列
export const travel_address_list_request = () => {
    return request({
        method:'GET',
        url:'travel_address/list'
    })
}

//休假类型序列
export const vacation_category_list_request = () => {
    return request({
        method:'GET',
        url:'vacation_category/list'
    })
}

//印章使用申请类型序列
export const currency_category_list_request = () => {
    return request({
        method:'GET',
        url:'currency_category/list'
    })
}

// 装卸平台序列
export const terrace_list_request = () => {
    return request({
        method:'GET',
        url:'terrace/list'
    })
}