import request from '../request'

//合同列表
export const contract_index_request = p => {
    return request({
        method:'GET',
        url:'contract/index',
        params: p
    })
}

//合同详情
export const contract_details_request = id => {
    return request({
        method:'GET',
        url:'contract/details',
        params: {
            id
        }
    })
}

// 提交合同
export const contract_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'contract/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看合同审批记录
export const contract_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'contract/sel_approval',
        params: {
            id
        }
    })
}

// 审批合同
export const contract_approval_request = d => {
    return request({
        method:'PUT',
        url:'contract/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other
        }
    })
}

// 撤回合同
export const contract_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'contract/withdraw',
        data: {
            id: d.id
        }
    })
}