<template>
    <div class="ContractView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="合同单号"
                    placeholder="合同单号"
                />
                <select-component
                    name="state"
                    lable="审核状态"
                    :farr="this.$store.state.state"
                    @clk="confirm_state"
                ></select-component>
                <select-component
                    name="record_state"
                    lable="备案状态"
                    :farr="[{id: 0,name: '未备案'},{id: 1,name: '已备案'}]"
                    @clk="confirm_record_state"
                ></select-component>
                <select-component
                    name="subject_num"
                    lable="主体数量"
                    :farr="[{id: 0,name: '双方协议'},{id: 1,name: '三方协议'},{id: 2,name: '四方协议'}]"
                    @clk="confirm_subject_num"
                ></select-component>
                <select-component
                    name="contract_type"
                    lable="合同类型"
                    :farr="[{id: 0,name: '采购'},{id: 1,name: '销售'}]"
                    @clk="confirm_contract_type"
                ></select-component>
                <select-component
                    name="enterprise_id"
                    lable="所属企业"
                    :farr="all_enterprise"
                    @clk="confirm_enterprise"
                ></select-component>
                <select-component
                    name="staff_id"
                    lable="负责人"
                    :farr="all_staff"
                    @clk="confirm_staff"
                ></select-component>
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.title}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="v.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="v.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="v.state===3">已驳回</van-tag>
                        <span>&nbsp;</span>
                        <van-tag round color="#ADADAD" v-if="v.record_state===0">未备案</van-tag>
                        <van-tag round type="success" v-else-if="v.record_state===1">已备案</van-tag>
                        <span>&nbsp;</span>
                        <van-tag round color="#ADADAD" v-if="v.urgent_state===0">一般</van-tag>
                        <van-tag round type="warning" v-else-if="v.urgent_state===1">紧急</van-tag>
                        <van-tag round type="danger" v-else-if="v.urgent_state===2">特急</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="单号" :value="v.name" :border="false" />
                <van-cell class="note_cell" title="我方企业" :value="v.enterprise_name" :border="false" />
                <van-cell class="note_cell" title="开始日期" :value="v.start_date" :border="false" />
                <van-cell class="note_cell" title="结束日期" :value="v.end_date" :border="false" />
                <van-cell class="note_cell" title="负责人" :value="v.staff_name" :border="false" />
                <van-cell class="note_cell" title="当前审批人" :value="v.current_examine_staff_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'

import { contract_index_request } from '@/network/finance/Contract.js'
import { enterprise_list_request,staff_list_request } from '@/network/list.js'

export default {
    name:'ContractView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '合同管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                state: '',
                record_state: '',
                subject_num: '',
                contract_type: '',
                enterprise_id: '',
                staff_id: '',
                page: 1
            },
            all_enterprise: [],
            all_staff: []
        }
    },
    computed:{},
    methods:{
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_contract() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            contract_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_state(e) {
            this.cond.state = e.id
        },
        confirm_record_state(e) {
            this.cond.record_state = e.id
        },
        confirm_subject_num(e) {
            this.cond.subject_num = e.id
        },
        confirm_contract_type(e) {
            this.cond.contract_type = e.id
        },
        confirm_enterprise(e) {
            this.cond.enterprise_id = e.id
        },
        confirm_staff(e) {
            this.cond.staff_id = e.id
        },
        search() {
            this.cond.page = 1
            this.get_contract()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                state: '',
                record_state: '',
                subject_num: '',
                contract_type: '',
                enterprise_id: '',
                staff_id: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if(this.$_has('合同详情')) {
                this.$router.push({
                    name: 'ContractDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                contract_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_contract()
        this.get_all_enterprise()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent
    },
    watch:{}
}
</script>

<style>
.ContractView .van-cell-group {
    margin-bottom: 0.2rem;
}
.ContractView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.ContractView .note_cell {
    padding: 0.1rem 0.42667rem
}
.ContractView .search_title {
    text-align: center;
}
.ContractView .search_button {
    margin: 0.5rem;
}
.ContractView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>